document.addEventListener('DOMContentLoaded', function () {
    const form = document.getElementById('eventForm');
    if (!form) return;

    const event_type_dropdown = document.getElementById('event_type');
    const points_system_dropdown = document.getElementById('points_system');
    const leagueSettings = document.getElementById('league_settings');
    const points_per_second_settings = document.getElementById('points_per_second_settings');
    const league_participants_settings = document.getElementById('league_participants_settings');

    const addCategoryUrlButton = document.getElementById('addCategoryUrl');
    const addThresholdButtons = document.getElementsByClassName('addThresholdButton');
    const addParticipantButton = document.getElementById('addParticipantButton');

    const categoryUrlColumn = document.getElementById('categoryUrlColumn');
    const hackColumn = document.getElementById('hackColumn');
    const categoryNameColumn = document.getElementById('categoryNameColumn');
    const bonusPointsColumn = document.getElementById('bonusPointsColumn');
    const removeButtonColumn = document.getElementById('removeButtonColumn');

    const usernameColumn = document.getElementById('nameColumn');
    const srcNameColumn = document.getElementById('srcNameColumn');
    const removeParticipantColumn = document.getElementById('removeParticipantColumn');

    const addEventListener = (button, columnContainer) => {
        button.addEventListener('click', (event) => {
            event.preventDefault();
            const index = Array.from(columnContainer.children).indexOf(event.target);
            if (index !== -1) {
                Array.from(columnContainer.parentNode.children).forEach((child) => {
                    console.log(child);
                    child.children[index].remove();
                });
            }
        });

        Array.from(button.getElementsByTagName('span')).forEach((element) => {
            element.addEventListener('click', (event) => {
                event.preventDefault();
                button.click();
            });
        });
    }

    if (event_type_dropdown.value == 2) {
        leagueSettings.hidden = false;
        league_participants_settings.hidden = false;
    }

    if (points_system_dropdown.value == 1) {
        points_per_second_settings.hidden = false;
    }

    event_type_dropdown.addEventListener('change', (event) => {
        if (event.target.value == 2) {
            leagueSettings.hidden = false;
            league_participants_settings.hidden = false;
        }
        else {
            leagueSettings.hidden = true;
            league_participants_settings.hidden = true;
        }
    });

    points_system_dropdown.addEventListener('change', (event) => {
        if (event.target.value == 1) {
            points_per_second_settings.hidden = false;
        }
        else {
            points_per_second_settings.hidden = true;
        }
    });

    Array.from(document.getElementsByClassName('removeCategoryUrl')).forEach((button) => {
        addEventListener(button, removeButtonColumn);
    });

    addCategoryUrlButton.addEventListener('click', (event) => {
        event.preventDefault();
        addInputfield(categoryUrlColumn, 'url')
        addInputfield(bonusPointsColumn, 'number');
        addInputfield(hackColumn, 'text');
        addInputfield(categoryNameColumn, 'text');
        addRemoveButton(removeButtonColumn, addEventListener);
    });

    addParticipantButton.addEventListener('click', (event) => {
        event.preventDefault();
        addInputfield(usernameColumn, 'text');
        addInputfield(srcNameColumn, 'text');
        addRemoveButton(removeParticipantColumn, addEventListener);
    });

    Array.from(addThresholdButtons).forEach((button) => {
        const container = document.getElementById(button.name);
        const leagueCategoryIdColumn = container.querySelector('#leagueCategoryIdColumn')
        const cutoffColumn = container.querySelector('#cutoffColumn');
        const pointsPerSecondColumn = container.querySelector('#pointsPerSecondColumn');
        const tierColumn = container.querySelector('#tierColumn');
        const removeThresholdColumn = container.querySelector('#removeThresholdColumn');
        const removeThresholdButtons = container.querySelectorAll('button');

        button.addEventListener('click', (event) => {
            event.preventDefault();
            addInputfield(leagueCategoryIdColumn, 'hidden', button.value);
            addInputfield(cutoffColumn, 'text');
            addInputfield(pointsPerSecondColumn, 'number');
            addInputfield(tierColumn, 'number');
            addRemoveButton(removeThresholdColumn, addEventListener);
        });

        Array.from(removeThresholdButtons).forEach((removeButton) => {
            addEventListener(removeButton, removeThresholdColumn);
        });
    });


});

function addInputfield(columnContainer, type, value = null) {
    const inputElement = document.createElement('input');

    inputElement.setAttribute('class', 'form-control mb-3');
    inputElement.setAttribute('type', type);
    inputElement.setAttribute('name', columnContainer.getAttribute('name') + '[]');

    if (type == 'hidden') {
        inputElement.setAttribute('value', value);
    }

    if (columnContainer.getAttribute('name') == 'hack') {
        inputElement.setAttribute('list', 'hacknames')
    }

    columnContainer.appendChild(inputElement);
}

function addRemoveButton(columnContainer, eventListener) {
    const button = document.createElement('button');
    const minusIcon = document.createElement('span');

    button.setAttribute('class', 'btn btn-danger removeCategoryUrl d-block mb-3');
    minusIcon.setAttribute('class', 'fa-solid fa-minus');

    button.appendChild(minusIcon);
    columnContainer.appendChild(button);

    eventListener(button, columnContainer);
}